import React from 'react';
import './Logo.css';

const Logo: React.FC = () => {
  return (
    <div className="logo-container">
      <img src="logo.png" alt="Logo" className="logo" />
      <h1 className="title">Crowlab</h1>
    </div>
  );
};

export default Logo;
